import React from 'react';
import './SearchTool.css';

import CardCollection from './CardCollection.js';

class SearchTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: '', matchingCards: []};
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        if(this.state.value && this.state.value.length > 0) {
            this.setState({
                matchingCards: this.props.cardData
                    .filter(card => {
                        let matches = card.name.match(new RegExp(this.state.value, 'gi'));
                        return matches && matches.length > 0;
                    })
            });
        }
        event.preventDefault();
    }

    handleReset(event) {
        this.setState({value: '', matchingCards: []});
    }

    render() {
        return (
            <div className="SearchTool">
                <h1>Search</h1>
                <p>Find cards to add to your collection. Search uses case-insensitive regular expression. This means you can provide a search term like '^burn' to find matches that begin with 'burn' or 'cat$' to find matches that end with 'cat'.</p>
                <form className="pure-form" onSubmit={this.handleSubmit} onReset={this.handleReset}>
                    <label>Name matches: <input type="text" name="search-string" value={this.state.value} onChange={this.handleChange} /></label>
                    <button type="submit" className="pure-button">Find Cards</button>
                    <button type="reset" className="pure-button">Reset</button>
                </form>
            {/*<p>{this.props.cardData.length} matches.</p>*/}
                <p>{this.state.matchingCards.length} matches.</p>
            {this.state.matchingCards && this.state.matchingCards.length > 0 &&
                    <CardCollection collectionData={this.state.matchingCards} />}
            </div>
        );
    }
}

export default SearchTool;
