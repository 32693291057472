const _dbConfig = { name: 'YugiohDB'
    ,version: 1 };

const _defaultCollectionIds = [34541863,64163367,91231901,73262676,37478723];

// const _defaultCard = { 
//     id: 1234, 
//     name: 'Default', 
//     type: 'Default Type',
//     desc: 'A default card that probably indicates an error occured if seen.',
//     race: 'Default',
//     archetype: 'Default',
//     card_sets: [],
//     card_images: [],
//     card_prices: [],
// };

class Store {
    constructor() {
        let req = indexedDB.open(_dbConfig.name, _dbConfig.version);
        let initPromise = Promise.resolve(0);

        req.onupgradeneeded = e => {
            let db = e.target.result;

            let createPromise =
                new Promise(resolveCreate => {
                    db.createObjectStore('cards', { keyPath: 'id' }).transaction.oncomplete = e => {
                        resolveCreate(0);
                    };
                });

            let seedPromise = 
                fetch('cardinfo.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } })
                    .then(r => {
                        return r.json();
                    })
                    .then(inAllCards => {
                        let txPromise = new Promise(resolveTx => {

                            let tx = db.transaction('cards', 'readwrite');
                            let cardStore = tx.objectStore('cards');

                            inAllCards.data.forEach(c => {
                                cardStore.add(c);
                            });

                            tx.oncomplete = e => {
                                resolveTx(db);
                            };
                        });

                        return txPromise;
                    });

            initPromise = createPromise.then(() => { return seedPromise; });
        };

        this._db = new Promise(resolveDb => {
            req.onsuccess = e => {
                initPromise.then(initResult => {
                    if(initResult === 0) {
                        resolveDb(e.target.result);
                    } else {
                        resolveDb(initResult);
                    }
                });
            }
        });
    }

    async *getAllCards() {
        let db = await this._db;

        let cursorStart = new Promise(resolveCursorStart => {
            const sendValue = (() => {
                let resolver = resolveCursorStart;

                return (cursor, nextResolver) => {
                    resolver(cursor);
                    resolver = nextResolver;
                };
            })();

            db.transaction('cards').objectStore('cards').openCursor().onsuccess = e => {
                let cursor = e.target.result;
                let nextResolver = null;

                let nextPromise = new Promise(newResolver => {
                    nextResolver = newResolver;
                });

                if(cursor) {
                    sendValue({ 
                        value: cursor.value,
                        nextResult: nextPromise,
                    }, nextResolver);
                    cursor.continue();
                } else {
                    // Done!
                    sendValue({
                        value: null,
                        nextResult: null,
                    }, null);
                }
            };
        });

        let result = await cursorStart;
        while(result) {
            if(result.value) {
                yield result.value;
            }
            result = await result.nextResult;
        }
    }

    *getCollectionIds() {
        let inCollectionIds = JSON.parse(localStorage.getItem('collectionIds'))
            ?? (() => {
                localStorage.setItem('collectionIds', JSON.stringify(_defaultCollectionIds));
                return _defaultCollectionIds;
            })();

        for (let i = 0; i < inCollectionIds.length; i++) {
            yield inCollectionIds[i];
        }
    }

    Cards = {
        [Symbol.asyncIterator]: this.getAllCards.bind(this)
    };

    CollectionIds = {
        [Symbol.iterator]: this.getCollectionIds,
        
        [Symbol.asyncIterator]: async function* () {
            let wrappedCollectionIds = [...this].map(id => Promise.resolve(id));
            while (wrappedCollectionIds.length) {
                yield await wrappedCollectionIds.shift();
            }
        }
    };
}

export default Store;
