import React from 'react';
import logo from './logo.svg';
import './App.css';

import Store from './Store.js';

import SearchTool from './SearchTool.js';
import CardCollection from './CardCollection.js';

const dataStore = new Store();

function App() {
    // console.log('App.render()');

    const [allCards,setAllCards] = React.useState([]);
    const [collectionIds,setCollectionIds] = React.useState([]);

    // This useEffect only runs once because of the empty array '[]' passed as
    // the second argument.
    React.useEffect(() => {
        setAllCards([]);
        (async function() {
            for await (const card of dataStore.Cards) {
                setAllCards(cards => {
                    return cards.concat([card]);
                });
            }
        })();

        setCollectionIds([]);
        (async function() {
            for await (const id of dataStore.CollectionIds) {
                setCollectionIds(ids => {
                    return ids.concat([id]);
                });
            }
        })();
    },[]);

    return (
        <div className="App">
            <header className="App-header">
                <div>
                    <img src={logo} className="App-logo" alt="Icon of two playing cards, one shows a sword, one shows a shield" />
                </div>
                <div>
                    <h1>YuGiOh Card Collection Manager</h1>
                </div>
            </header>
            <section>
                <SearchTool cardData={allCards} />
            </section>
            <section className="App-collection">
                <h2>Your personal collection of cards shows up here.</h2>
                <p>{collectionIds && collectionIds.length} cards in collection.</p>
                <CardCollection collectionData={allCards.filter(card => collectionIds.includes(card.id))} />
            </section>
            <footer className="App-footer">
                <p>{allCards.length} cards known.</p>
            </footer>
        </div>
    );
}

export default App;
