import './CardCollection.css';
import 'animate.css/animate.css';

import Card from './Card.js';

function CardCollection(props) {
    const cards = props.collectionData.map(c => 
        <div key={c.id} className="CardCollection-card-container">
            <Card key={c.id} cardData={c} />
        </div>
    );
    return (
        <div className="CardCollection">
            <div className="CardCollection-list">
                {cards}
            </div>
        </div>
    );
}

export default CardCollection;
