import './Card.css';
import monster from './default-monster.svg';

function Card(props) {
    const intToFilter = (i) =>  {
        const filterCnt = 5;
        return 'Card-filter-' + (parseInt(i) % filterCnt);
    };

    const typeToColor = (t) => {
        const typeColorMap = new Map([
            ['Spell Card', 'Card-color-1'],
            ['Skill Card', 'Card-color-2'],
            ['Token', 'Card-color-3'],
            ['Trap Card', 'Card-color-4'],
            ['Effect Monster', 'Card-color-5'],
            ['Flip Effect Monster', 'Card-color-5'],
            ['Fusion Monster', 'Card-color-5'],
            ['Gemini Monster', 'Card-color-5'],
            ['Link Monster', 'Card-color-5'],
            ['Normal Monster', 'Card-color-5'],
            ['Normal Tuner Monster', 'Card-color-5'],
            ['Pendulum Effect Fusion Monster', 'Card-color-5'],
            ['Pendulum Effect Monster', 'Card-color-5'],
            ['Pendulum Flip Effect Monster', 'Card-color-5'],
            ['Pendulum Normal Monster', 'Card-color-5'],
            ['Pendulum Tuner Effect Monster', 'Card-color-5'],
            ['Ritual Effect Monster', 'Card-color-5'],
            ['Ritual Monster', 'Card-color-5'],
            ['Spirit Monster', 'Card-color-5'],
            ['Synchro Monster', 'Card-color-5'],
            ['Synchro Pendulum Effect Monster', 'Card-color-5'],
            ['Synchro Tuner Monster', 'Card-color-5'],
            ['Toon Monster', 'Card-color-5'],
            ['Tuner Monster', 'Card-color-5'],
            ['Union Effect Monster', 'Card-color-5'],
            ['XYZ Monster', 'Card-color-5'],
            ['XYZ Pendulum Effect Monster', 'Card-color-5'],
        ]);

        return typeColorMap.get(t) ?? 'Card-color-0';
    };

    return (
        <div className={ ['Card', typeToColor(props.cardData.type)].join(' ') }> 
            <div className="Card-body"> 
                <header className="Card-header">
                    <h1>{ props.cardData.name }</h1>
                    <p>{ props.cardData.type }</p>
                </header>
                <section className="Card-image">
                    <img className={intToFilter(props.cardData.id)} src={monster} height="225" width="225" alt="A scary monster" />
                </section>
                <section className="Card-description">
                    <p>{ props.cardData.desc }</p>
                </section>
                <footer className="Card-footer">
                    <p>{ props.cardData.id }</p>
                </footer>
            </div>
        </div>
    );
};

export default Card;
